import React, { useEffect } from 'react'
import styles from './FunnelOptions.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Popup from './Popup'
import { fetchFunnelsOptions, fetchMarketingFunnels } from '../../store/funnelOptions/funnelOptions.slice'
import MarketingFunnelPopup from './MarketingFunnelPopup'

const FunnelOptions = () => {
    const location = useLocation();
    const { id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const funnelOptions = useSelector(state => state.funnelOptions.data.funnelOptions)
    const marketingFunnels = useSelector(state => state.funnelOptions.data.marketingFunnels)
    const isMarketingFunnelRoute = location.pathname.includes('/marketingFunnel/');
    const isFunnelOptionRoute = location.pathname.includes('/funnelOption/');

    useEffect(() => {
        dispatch(fetchFunnelsOptions())
        dispatch(fetchMarketingFunnels())
    }, [dispatch])

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <table className={styles.table}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.title}>Organize Poll</th>
                    <th className={styles.num}></th>
                </tr>
                {
                    funnelOptions.map(d =>
                        <RowView
                            key={d.id}
                            id={d.id} />
                    )
                }
            </table>

            <table className={styles.table}>
                <tr className={styles.tableHeaderRow}>
                    <th className={styles.title} style={{cursor: 'pointer'}} onClick={() => navigate(`/funnel_options/marketingFunnel/`)}>Marketing Funnel (Press to Add)</th>
                    <th className={styles.num}>info</th>
                    <th className={styles.num}>from</th>
                    <th className={styles.num}>promo</th>
                </tr>
                {
                    Object.keys(marketingFunnels).sort().map(d =>
                        <RowMarketingFunnelsView
                            key={d}
                            funnel={marketingFunnels[d]}
                            id={d}
                        />
                    )
                }
            </table>
            {
                isFunnelOptionRoute ?
                <Popup id={id} /> : null
            }
            {
                isMarketingFunnelRoute ? 
                <MarketingFunnelPopup id={id} /> : null
            }
        </Layout>
    )
}

const RowView = ({ id }) => {

    const navigate = useNavigate()

    return (
        <tr className={styles.tableRow} onClick={() => { navigate(`/funnel_options/funnelOption/${id}`) }}>
            <td className={styles.title}>{id}</td>
            <td className={styles.num}></td>
        </tr>
    )
}

const RowMarketingFunnelsView = ({ funnel, id }) => {

    const navigate = useNavigate()

    return (
        <tr className={styles.tableRow} onClick={() => { navigate(`/funnel_options/marketingFunnel/${id}`) }}>
            <td className={styles.title}>{id}</td>
            <td className={styles.num}>{funnel.info}</td>
            <td className={styles.num}>{funnel.from}</td>
            <td className={styles.num}>{funnel.promo}</td>
        </tr>
    )
}

export default FunnelOptions