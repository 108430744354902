import React, { useState } from 'react'
import { useLocation } from "react-router-dom"
import Home from '../../assets/icons/Home'
import Triangles from '../../assets/icons/Triangles'
import AuthService from '../../services/AuthService'
import styles from './SideBar.module.css'
import Layout from '../../components/Layout/Layout'
import MenuButton from './MenuButton'
import { useSelector } from 'react-redux'
import Text from '../Text'

const SideBar = () => {
    const sidebarActions = useSelector(state => state.user.data?.sidebar ?? [])
    const location = useLocation().pathname.substring(1).split('/')[0]
    const [activePage, setActivePage] = useState(location)

    return (
        <div className={styles.container}>
            <Layout row fullWidth padding={[16, 0, 16, 24]} justifyContent='flex-start'>
                <Text text="RapidSight" className={styles.textLogo}/>
            </Layout>
            <Layout padding={[8]} style={{ zIndex: 2, height: '100%' }} justifyContent='space-between'>
                <Layout gap={8} style={{ maxHeight: '70vh', overflow: 'auto', width: '180px' }} justifyContent='flex-start'>
                    <MenuButton Icon={Home} text='Home' href='/' onClick={() => setActivePage("")} selected={activePage === ''} />
                    <MenuButton hide={!sidebarActions.includes('submissions')} text='Submissions' href='/submissions' onClick={() => setActivePage("submissions")} selected={activePage === 'submissions'} />
                    <MenuButton hide={!sidebarActions.includes('marketing')} text='Marketing' href='/marketing' onClick={() => setActivePage("marketing")} selected={activePage === 'marketing'} />
                    <MenuButton hide={!sidebarActions.includes('cohorts')} text='Cohorts' href='/cohorts' onClick={() => setActivePage("cohorts")} selected={activePage === 'cohorts'} />
                    <MenuButton hide={!sidebarActions.includes('permissions')} text='Permissions' href='/permissions' onClick={() => setActivePage("permissions")} selected={activePage === 'permissions'} />
                    <MenuButton hide={!sidebarActions.includes('payments')} text='Payments' href='/payments' onClick={() => setActivePage("payments")} selected={activePage === 'payments'} />
                    <MenuButton hide={!sidebarActions.includes('tagging')} text='Tagging' href='/tagging' onClick={() => setActivePage("tagging")} selected={activePage === 'tagging'} />
                    <MenuButton hide={!sidebarActions.includes('support')} text='Support' href='/support' onClick={() => setActivePage("support")} selected={activePage === 'support'} />
                    <MenuButton hide={!sidebarActions.includes('contact')} text='Contact' href='/contact' onClick={() => setActivePage("contact")} selected={activePage === 'contact'} />
                    <MenuButton hide={!sidebarActions.includes('carousels')} text='Carousels' href='/carousels' onClick={() => setActivePage("carousels")} selected={activePage === 'carousels'} />
                    <MenuButton hide={!sidebarActions.includes('ai_feedbacks')} text='AI Feedbacks' href='/ai_feedbacks' onClick={() => setActivePage("ai_feedbacks")} selected={activePage === 'ai_feedbacks'} />
                    <MenuButton hide={!sidebarActions.includes('blogs')} text='Blogs' href='/blogs' onClick={() => setActivePage("blogs")} selected={activePage === 'blogs'} />
                    <MenuButton hide={!sidebarActions.includes('coupons')} text='Coupons' href='/coupons' onClick={() => setActivePage("coupons")} selected={activePage === 'coupons'} />
                    <MenuButton hide={!sidebarActions.includes('feedbacks')} text='Feedbacks' href='/feedbacks' onClick={() => setActivePage("feedbacks")} selected={activePage === 'feedbacks'} />
                    <MenuButton hide={!sidebarActions.includes('journeys')} text='Journeys' href='/journeys' onClick={() => setActivePage("journeys")} selected={activePage === 'journeys'} />
                    <MenuButton hide={!sidebarActions.includes('cancellations')} text='Cancellations' href='/cancellations' onClick={() => setActivePage("cancellations")} selected={activePage === 'cancellations'} />
                    <MenuButton hide={!sidebarActions.includes('challenges')} text='Challenges' href='/challenges' onClick={() => setActivePage("challenges")} selected={activePage === 'challenges'} />
                    <MenuButton hide={!sidebarActions.includes('courses')} text='Courses' href='/courses' onClick={() => setActivePage("courses")} selected={activePage === 'courses'} />
                    <MenuButton hide={!sidebarActions.includes('customers')} text='Customers' href='/customers' onClick={() => setActivePage("customers")} selected={activePage === 'customers'} />
                    <MenuButton hide={!sidebarActions.includes('royalties')} text='Royalties' href='/royalties' onClick={() => setActivePage("royalties")} selected={activePage === 'royalties'} />
                    <MenuButton hide={!sidebarActions.includes('ai_responses')} text='AI Responses' href='/ai_responses' onClick={() => setActivePage("ai_responses")} selected={activePage === 'ai_responses'} />
                    <MenuButton hide={!sidebarActions.includes('courseAnalytics')} text='Course Analytics' href='/courseAnalytics' onClick={() => setActivePage("courseAnalytics")} selected={activePage === 'courseAnalytics'} />
                    <MenuButton hide={!sidebarActions.includes('marketing_funnels')} text='Marketing Funnels' href='/marketing_funnels' onClick={() => setActivePage("marketing_funnels")} selected={activePage === 'marketing_funnels'} />
                    <MenuButton hide={!sidebarActions.includes('insights')} text='Insights' href='/insights' onClick={() => setActivePage("insights")} selected={activePage === 'insights'} />
                    <MenuButton hide={!sidebarActions.includes('funnel_options')} text='Edit Influencers' href='/funnel_options' onClick={() => setActivePage("funnel_options")} selected={activePage === 'funnel_options'} />
                    <MenuButton hide={!sidebarActions.includes('leaderboards')} text='Leaderboards' href='/leaderboards' onClick={() => setActivePage("leaderboards")} selected={activePage === 'leaderboards'} />
                    <MenuButton hide={!sidebarActions.includes('user_metrics')} text='User Metrics' href='/user_metrics' onClick={() => setActivePage("user_metrics")} selected={activePage === 'user_metrics'} />
                </Layout>
                <Layout fullWidth gap={12}>
                    <MenuButton text='Settings' href='/settings' onClick={() => setActivePage("settings")} selected={activePage === 'settings'} />
                    <MenuButton text='Logout' onClick={() => AuthService.logout()} />
                </Layout>
            </Layout>
            <div className={styles.Triangles}>
                <Triangles />
            </div>

        </div>
    )
}

export default SideBar