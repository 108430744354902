import { toast } from "react-toastify"
import Status from "../status"

export const fetchFunnelsOptionsLoading = (state, _) => {
    state.fetchStatus = Status.Loading
}

export const fetchFunnelsOptionsFulfilled = (state, action) => {
    state.fetchStatus = Status.Success
    state.data.funnelOptions = action.payload
}

export const fetchFunnelsOptionsRejected = (state, action) => {
    state.fetchStatus = Status.Fail
    toast.error(action.error.message)
}


export const fetchMarketingFunnelsLoading = (state, _) => {
    state.fetchMarketingFunnelsStatus = Status.Loading
}

export const fetchMarketingFunnelsFulfilled = (state, action) => {
    state.fetchMarketingFunnelsStatus = Status.Success
    state.data.marketingFunnels = action.payload
}

export const fetchMarketingFunnelsRejected = (state, action) => {
    state.fetchMarketingFunnelsStatus = Status.Fail
    toast.error(action.error.message)
}

export const saveFunnelOptionsLoading = (state, _) => {
    state.saveStatus = Status.Loading
}

export const saveFunnelOptionsFulfilled = (state, action) => {
    state.saveStatus = Status.Success
    state.data.funnelOptions = state.data.funnelOptions.map(d => d.id === action.meta.arg.id ? action.meta.arg.data : d)
    toast.success('Successfully updated')
}

export const saveFunnelOptionsRejected = (state, action) => {
    state.saveStatus = Status.Fail
    toast.error(action.error.message)
}

export const saveMarketingFunnelLoading = (state, _) => {
    state.saveMarketingFunnelStatus = Status.Loading
}

export const saveMarketingFunnelFulfilled = (state, action) => {
    state.saveMarketingFunnelStatus = Status.Success
    state.data.marketingFunnels = {
        ...state.data.marketingFunnels,
        [action.meta.arg.id]: action.meta.arg.data
      }
    action.meta.arg.navigate(`/funnel_options/marketingFunnel/${action.meta.arg.id}`)
    toast.success('Successfully updated')
}

export const saveMarketingFunnelRejected = (state, action) => {
    state.saveMarketingFunnelStatus = Status.Fail
    toast.error(action.error.message)
}