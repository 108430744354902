import React, { useEffect } from 'react'
import styles from './Backdrop.module.css'

const Backdrop = ({ children, onCancel, show }) => {

    useEffect(() => {
        if (show) {
            document.getElementsByTagName('body')[0].classList.add(styles.show);
        } else {
            document.getElementsByTagName('body')[0].classList.remove(styles.show);
        }
        return () => {
            document.getElementsByTagName('body')[0].classList.remove(styles.show);
        }
    }, [show])

    return (
        <div className={styles.container} onClick={onCancel}>
            <div onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default Backdrop