import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import FunctionsService from '../../services/FunctionsService'
import Status from '../status'
import {
  fetchDailyActiveDataFulfilled,
  fetchDailyActiveDataLoading,
  fetchDailyActiveDataRejected,
  fetchWeeklyActiveDataFulfilled,
  fetchWeeklyActiveDataLoading,
  fetchWeeklyActiveDataRejected,
  fetchMonthlyActiveDataFulfilled,
  fetchMonthlyActiveDataLoading,
  fetchMonthlyActiveDataRejected,
  fetchDailyPremiumDataFulfilled,
  fetchDailyPremiumDataLoading,
  fetchDailyPremiumDataRejected,
  fetchWeeklyPremiumDataFulfilled,
  fetchWeeklyPremiumDataLoading,
  fetchWeeklyPremiumDataRejected,
  fetchMonthlyPremiumDataFulfilled,
  fetchMonthlyPremiumDataLoading,
  fetchMonthlyPremiumDataRejected,

  fetchLifetimePremiumDataLoading,
  fetchLifetimePremiumDataFulfilled,
  fetchLifetimePremiumDataRejected,

  fetchDailyUserRetentionDataLoading,
  fetchDailyUserRetentionDataFulfilled,
  fetchDailyUserRetentionDataRejected,
  fetchWeeklyUserRetentionDataLoading,
  fetchWeeklyUserRetentionDataFulfilled,
  fetchWeeklyUserRetentionDataRejected,
  fetchMonthlyUserRetentionDataLoading,
  fetchMonthlyUserRetentionDataFulfilled,
  fetchMonthlyUserRetentionDataRejected,
  fetchDailyUsersDataFulfilled,
  fetchDailyUsersDataLoading,
  fetchDailyUsersDataRejected,
  fetchWeeklyUsersDataFulfilled,
  fetchWeeklyUsersDataLoading,
  fetchWeeklyUsersDataRejected,
  fetchMonthlyUsersDataFulfilled,
  fetchMonthlyUsersDataLoading,
  fetchMonthlyUsersDataRejected,
  fetchDailyReferralDataFulfilled,
  fetchDailyReferralDataLoading,
  fetchDailyReferralDataRejected,
  fetchWeeklyReferralDataFulfilled,
  fetchWeeklyReferralDataLoading,
  fetchWeeklyReferralDataRejected,
  fetchMonthlyReferralDataFulfilled,
  fetchMonthlyReferralDataLoading,
  fetchMonthlyReferralDataRejected,
  fetchDailySupportDataFulfilled,
  fetchDailySupportDataLoading,
  fetchDailySupportDataRejected,
  fetchWeeklySupportDataFulfilled,
  fetchWeeklySupportDataLoading,
  fetchWeeklySupportDataRejected,
  fetchMonthlySupportDataFulfilled,
  fetchMonthlySupportDataLoading,
  fetchMonthlySupportDataRejected,
  fetchDailySubmissionsDataFulfilled,
  fetchDailySubmissionsDataLoading,
  fetchDailySubmissionsDataRejected,
  fetchWeeklySubmissionsDataFulfilled,
  fetchWeeklySubmissionsDataLoading,
  fetchWeeklySubmissionsDataRejected,
  fetchMonthlySubmissionsDataFulfilled,
  fetchMonthlySubmissionsDataLoading,
  fetchMonthlySubmissionsDataRejected,
  fetchDailyAIResponsesDataFulfilled,
  fetchDailyAIResponsesDataLoading,
  fetchDailyAIResponsesDataRejected,
  fetchWeeklyAIResponsesDataFulfilled,
  fetchWeeklyAIResponsesDataLoading,
  fetchWeeklyAIResponsesDataRejected,
  fetchMonthlyAIResponsesDataFulfilled,
  fetchMonthlyAIResponsesDataLoading,
  fetchMonthlyAIResponsesDataRejected,
  fetchKPIsDataFulfilled,
  fetchKPIsDataRejected,
} from './home.actions'

export const fetchDailyActiveData = createAsyncThunk('home/fetchDailyActiveData', async (_, { getState }) => {
  const data = getState().home.dailyActiveData
  if (data.length === 0) {
    return FunctionsService.getActiveUsersNums('daily', 30)
  } else {
    return data
  }
})

export const fetchWeeklyActiveData = createAsyncThunk('home/fetchWeeklyActiveData', async (_, { getState }) => {
  const data = getState().home.weeklyActiveData
  if (data.length === 0) {
    return FunctionsService.getActiveUsersNums('weekly', 16)
  } else {
    return data
  }
})

export const fetchMonthlyActiveData = createAsyncThunk('home/fetchMonthlyActiveData', async (_, { getState }) => {
  const data = getState().home.monthlyActiveData
  if (data.length === 0) {
    return FunctionsService.getActiveUsersNums('monthly', 12)
  } else {
    return data
  }
})

export const fetchDailyPremiumData = createAsyncThunk('home/fetchDailyPremiumData', async (_, { getState }) => {
  const data = getState().home.dailyPremiumData
  if (data.length === 0) {
    return FunctionsService.getPremiumUsersNums('daily', 90)
  } else {
    return data
  }
})

export const fetchWeeklyPremiumData = createAsyncThunk('home/fetchWeeklyPremiumData', async (_, { getState }) => {
  const data = getState().home.weeklyPremiumData
  if (data.length === 0) {
    return FunctionsService.getPremiumUsersNums('weekly', 24)
  } else {
    return data
  }
})

export const fetchMonthlyPremiumData = createAsyncThunk('home/fetchMonthlyPremiumData', async (_, { getState }) => {
  const data = getState().home.monthlyPremiumData
  if (data.length === 0) {
    return FunctionsService.getPremiumUsersNums('monthly', 12)
  } else {
    return data
  }
})

export const fetchLifetimePremiumData = createAsyncThunk('home/fetchLifetimePremiumData', async (_, { getState }) => {
  const data = getState().home.lifetimePremiumData
  if (data.length === 0) {
    return FunctionsService.getPremiumUsersNums('lifetime', 24)
  } else {
    return data
  }
})
export const fetchDailyUserRetentionData = createAsyncThunk('home/fetchDailyUserRetentionData', async (_, { getState }) => {
  const data = getState().home.dailyUserRetentionData
  if (data.length === 0) {
    return FunctionsService.getUsersRetention('daily', 90)
  } else {
    return data
  }
})

export const fetchWeeklyUserRetentionData = createAsyncThunk('home/fetchWeeklyUserRetentionData', async (_, { getState }) => {
  const data = getState().home.weeklyUserRetentionData
  if (data.length === 0) {
    return FunctionsService.getUsersRetention('weekly', 24)
  } else {
    return data
  }
})

export const fetchMonthlyUserRetentionData = createAsyncThunk('home/fetchMonthlyUserRetentionData', async (_, { getState }) => {
  const data = getState().home.monthlyUserRetentionData
  if (data.length === 0) {
    return FunctionsService.getUsersRetention('monthly', 12)
  } else {
    return data
  }
})

export const fetchDailyUsersData = createAsyncThunk('home/fetchDailyUsersData', async (_, { getState }) => {
  const data = getState().home.dailyUsersData
  if (data.length === 0) {
    return FunctionsService.getUsersNums('daily', 90)
  } else {
    return data
  }
})

export const fetchWeeklyUsersData = createAsyncThunk('home/fetchWeeklyUsersData', async (_, { getState }) => {
  const data = getState().home.weeklyUsersData
  if (data.length === 0) {
    return FunctionsService.getUsersNums('weekly', 24)
  } else {
    return data
  }
})

export const fetchMonthlyUsersData = createAsyncThunk('home/fetchMonthlyUsersData', async (_, { getState }) => {
  const data = getState().home.monthlyUsersData
  if (data.length === 0) {
    return FunctionsService.getUsersNums('monthly', 12)
  } else {
    return data
  }
})

export const fetchDailyReferralData = createAsyncThunk('home/fetchDailyReferralData', async (_, { getState }) => {
  const data = getState().home.dailyReferralData
  if (data.length === 0) {
    return FunctionsService.getReferralNums('DAY', 91).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchWeeklyReferralData = createAsyncThunk('home/fetchWeeklyReferralData', async (_, { getState }) => {
  const data = getState().home.weeklyReferralData
  if (data.length === 0) {
    return FunctionsService.getReferralNums('WEEK', 25).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchMonthlyReferralData = createAsyncThunk('home/fetchMonthlyReferralData', async (_, { getState }) => {
  const data = getState().home.monthlyReferralData
  if (data.length === 0) {
    return FunctionsService.getReferralNums('MONTH', 13).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchDailySupportData = createAsyncThunk('home/fetchDailySupportData', async (_, { getState }) => {
  const data = getState().home.dailySupportData
  if (data.length === 0) {
    return FunctionsService.getSupportNums('DAY', 91).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchWeeklySupportData = createAsyncThunk('home/fetchWeeklySupportData', async (_, { getState }) => {
  const data = getState().home.weeklySupportData
  if (data.length === 0) {
    return FunctionsService.getSupportNums('WEEK', 25).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchMonthlySupportData = createAsyncThunk('home/fetchMonthlySupportData', async (_, { getState }) => {
  const data = getState().home.monthlySupportData
  if (data.length === 0) {
    return FunctionsService.getSupportNums('MONTH', 13).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchDailySubmissionsData = createAsyncThunk('home/fetchDailySubmissionsData', async (_, { getState }) => {
  const data = getState().home.dailySubmissionsData
  if (data.length === 0) {
    return FunctionsService.getSubmissionsNums('DAY', 31).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchWeeklySubmissionsData = createAsyncThunk('home/fetchWeeklySubmissionsData', async (_, { getState }) => {
  const data = getState().home.weeklySubmissionsData
  if (data.length === 0) {
    return FunctionsService.getSubmissionsNums('WEEK', 17).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchMonthlySubmissionsData = createAsyncThunk('home/fetchMonthlySubmissionsData', async (_, { getState }) => {
  const data = getState().home.monthlySubmissionsData
  if (data.length === 0) {
    return FunctionsService.getSubmissionsNums('MONTH', 13).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchDailyAIResponsesData = createAsyncThunk('home/fetchDailyAIResponsesData', async (_, { getState }) => {
  const data = getState().home.dailyAIResponsesData
  if (data.length === 0) {
    return FunctionsService.getAIResponsesNums('DAY', 31).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchWeeklyAIResponsesData = createAsyncThunk('home/fetchWeeklyAIResponsesData', async (_, { getState }) => {
  const data = getState().home.weeklyAIResponsesData
  if (data.length === 0) {
    return FunctionsService.getAIResponsesNums('WEEK', 17).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchMonthlyAIResponsesData = createAsyncThunk('home/fetchMonthlyAIResponsesData', async (_, { getState }) => {
  const data = getState().home.monthlyAIResponsesData
  if (data.length === 0) {
    return FunctionsService.getAIResponsesNums('MONTH', 13).then(d => d.slice(1))
  } else {
    return data
  }
})

export const fetchKPIsData = createAsyncThunk('home/fetchKPIsData', async (id, { getState }) => {
  const data = getState().home.kpisData[id]
  if (data === undefined) {
    return DatabaseService.getKPIs(id)
  } else {
    return data
  }
})

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    dailyActiveData: [],
    weeklyActiveData: [],
    monthlyActiveData: [],
    dailyPremiumData: [],
    weeklyPremiumData: [],
    monthlyPremiumData: [],
    lifetimePremiumData: [],
    dailyUsersData: [],
    weeklyUsersData: [],
    monthlyUsersData: [],
    dailyReferralData: [],
    weeklyReferralData: [],
    monthlyReferralData: [],
    dailySupportData: [],
    weeklySupportData: [],
    monthlySupportData: [],
    dailyUserRetentionData: [],
    weeklyUserRetentionData: [],
    monthlyUserRetentionData: [],
    dailySubmissionsData: [],
    weeklySubmissionsData: [],
    monthlySubmissionsData: [],
    dailyAIResponsesData: [],
    weeklyAIResponsesData: [],
    monthlyAIResponsesData: [],
    kpisData: {},
    fetchDailyActiveDataStatus: Status.None,
    fetchWeeklyActiveDataStatus: Status.None,
    fetchMonthlyActiveDataStatus: Status.None,
    fetchDailyPremiumDataStatus: Status.None,
    fetchWeeklyPremiumDataStatus: Status.None,
    fetchMonthlyPremiumDataStatus: Status.None,
    fetchLifetimePremiumDataStatus: Status.None,
    fetchDailyUsersDataStatus: Status.None,
    fetchWeeklyUsersDataStatus: Status.None,
    fetchMonthlyUsersDataStatus: Status.None,
    fetchDailyReferralDataStatus: Status.None,
    fetchWeeklyReferralDataStatus: Status.None,
    fetchMonthlyReferralDataStatus: Status.None,
    fetchDailySupportDataStatus: Status.None,
    fetchWeeklySupportDataStatus: Status.None,
    fetchMonthlySupportDataStatus: Status.None,
    fetchDailyUserRetentionDataStatus: Status.None,
    fetchWeeklyUserRetentionDataStatus: Status.None,
    fetchMonthlyUserRetentionDataStatus: Status.None,
    fetchDailySubmissionsDataStatus: Status.None,
    fetchWeeklySubmissionsDataStatus: Status.None,
    fetchMonthlySubmissionsDataStatus: Status.None,
    fetchDailyAIResponsesDataStatus: Status.None,
    fetchWeeklyAIResponsesDataStatus: Status.None,
    fetchMonthlyAIResponsesDataStatus: Status.None,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDailyActiveData.pending, fetchDailyActiveDataLoading)
      .addCase(fetchDailyActiveData.fulfilled, fetchDailyActiveDataFulfilled)
      .addCase(fetchDailyActiveData.rejected, fetchDailyActiveDataRejected)
      .addCase(fetchWeeklyActiveData.pending, fetchWeeklyActiveDataLoading)
      .addCase(fetchWeeklyActiveData.fulfilled, fetchWeeklyActiveDataFulfilled)
      .addCase(fetchWeeklyActiveData.rejected, fetchWeeklyActiveDataRejected)
      .addCase(fetchMonthlyActiveData.pending, fetchMonthlyActiveDataLoading)
      .addCase(fetchMonthlyActiveData.fulfilled, fetchMonthlyActiveDataFulfilled)
      .addCase(fetchMonthlyActiveData.rejected, fetchMonthlyActiveDataRejected)
      .addCase(fetchDailyPremiumData.pending, fetchDailyPremiumDataLoading)
      .addCase(fetchDailyPremiumData.fulfilled, fetchDailyPremiumDataFulfilled)
      .addCase(fetchDailyPremiumData.rejected, fetchDailyPremiumDataRejected)
      .addCase(fetchWeeklyPremiumData.pending, fetchWeeklyPremiumDataLoading)
      .addCase(fetchWeeklyPremiumData.fulfilled, fetchWeeklyPremiumDataFulfilled)
      .addCase(fetchWeeklyPremiumData.rejected, fetchWeeklyPremiumDataRejected)
      .addCase(fetchMonthlyPremiumData.pending, fetchMonthlyPremiumDataLoading)
      .addCase(fetchMonthlyPremiumData.fulfilled, fetchMonthlyPremiumDataFulfilled)
      .addCase(fetchMonthlyPremiumData.rejected, fetchMonthlyPremiumDataRejected)

      .addCase(fetchLifetimePremiumData.pending, fetchLifetimePremiumDataLoading)
      .addCase(fetchLifetimePremiumData.fulfilled, fetchLifetimePremiumDataFulfilled)
      .addCase(fetchLifetimePremiumData.rejected, fetchLifetimePremiumDataRejected)

      .addCase(fetchDailyUsersData.pending, fetchDailyUsersDataLoading)
      .addCase(fetchDailyUsersData.fulfilled, fetchDailyUsersDataFulfilled)
      .addCase(fetchDailyUsersData.rejected, fetchDailyUsersDataRejected)
      .addCase(fetchWeeklyUsersData.pending, fetchWeeklyUsersDataLoading)
      .addCase(fetchWeeklyUsersData.fulfilled, fetchWeeklyUsersDataFulfilled)
      .addCase(fetchWeeklyUsersData.rejected, fetchWeeklyUsersDataRejected)
      .addCase(fetchMonthlyUsersData.pending, fetchMonthlyUsersDataLoading)
      .addCase(fetchMonthlyUsersData.fulfilled, fetchMonthlyUsersDataFulfilled)
      .addCase(fetchMonthlyUsersData.rejected, fetchMonthlyUsersDataRejected)
      .addCase(fetchDailyReferralData.pending, fetchDailyReferralDataLoading)
      .addCase(fetchDailyReferralData.fulfilled, fetchDailyReferralDataFulfilled)
      .addCase(fetchDailyReferralData.rejected, fetchDailyReferralDataRejected)
      .addCase(fetchWeeklyReferralData.pending, fetchWeeklyReferralDataLoading)
      .addCase(fetchWeeklyReferralData.fulfilled, fetchWeeklyReferralDataFulfilled)
      .addCase(fetchWeeklyReferralData.rejected, fetchWeeklyReferralDataRejected)
      .addCase(fetchMonthlyReferralData.pending, fetchMonthlyReferralDataLoading)
      .addCase(fetchMonthlyReferralData.fulfilled, fetchMonthlyReferralDataFulfilled)
      .addCase(fetchMonthlyReferralData.rejected, fetchMonthlyReferralDataRejected)
      .addCase(fetchDailySupportData.pending, fetchDailySupportDataLoading)
      .addCase(fetchDailySupportData.fulfilled, fetchDailySupportDataFulfilled)
      .addCase(fetchDailySupportData.rejected, fetchDailySupportDataRejected)
      .addCase(fetchWeeklySupportData.pending, fetchWeeklySupportDataLoading)
      .addCase(fetchWeeklySupportData.fulfilled, fetchWeeklySupportDataFulfilled)
      .addCase(fetchWeeklySupportData.rejected, fetchWeeklySupportDataRejected)
      .addCase(fetchMonthlySupportData.pending, fetchMonthlySupportDataLoading)
      .addCase(fetchMonthlySupportData.fulfilled, fetchMonthlySupportDataFulfilled)
      .addCase(fetchMonthlySupportData.rejected, fetchMonthlySupportDataRejected)

      .addCase(fetchDailyUserRetentionData.pending, fetchDailyUserRetentionDataLoading)
      .addCase(fetchDailyUserRetentionData.fulfilled, fetchDailyUserRetentionDataFulfilled)
      .addCase(fetchDailyUserRetentionData.rejected, fetchDailyUserRetentionDataRejected)

      .addCase(fetchWeeklyUserRetentionData.pending, fetchWeeklyUserRetentionDataLoading)
      .addCase(fetchWeeklyUserRetentionData.fulfilled, fetchWeeklyUserRetentionDataFulfilled)
      .addCase(fetchWeeklyUserRetentionData.rejected, fetchWeeklyUserRetentionDataRejected)

      .addCase(fetchMonthlyUserRetentionData.pending, fetchMonthlyUserRetentionDataLoading)
      .addCase(fetchMonthlyUserRetentionData.fulfilled, fetchMonthlyUserRetentionDataFulfilled)
      .addCase(fetchMonthlyUserRetentionData.rejected, fetchMonthlyUserRetentionDataRejected)

      .addCase(fetchDailySubmissionsData.pending, fetchDailySubmissionsDataLoading)
      .addCase(fetchDailySubmissionsData.fulfilled, fetchDailySubmissionsDataFulfilled)
      .addCase(fetchDailySubmissionsData.rejected, fetchDailySubmissionsDataRejected)
      .addCase(fetchWeeklySubmissionsData.pending, fetchWeeklySubmissionsDataLoading)
      .addCase(fetchWeeklySubmissionsData.fulfilled, fetchWeeklySubmissionsDataFulfilled)
      .addCase(fetchWeeklySubmissionsData.rejected, fetchWeeklySubmissionsDataRejected)
      .addCase(fetchMonthlySubmissionsData.pending, fetchMonthlySubmissionsDataLoading)
      .addCase(fetchMonthlySubmissionsData.fulfilled, fetchMonthlySubmissionsDataFulfilled)
      .addCase(fetchMonthlySubmissionsData.rejected, fetchMonthlySubmissionsDataRejected)
      .addCase(fetchDailyAIResponsesData.pending, fetchDailyAIResponsesDataLoading)
      .addCase(fetchDailyAIResponsesData.fulfilled, fetchDailyAIResponsesDataFulfilled)
      .addCase(fetchDailyAIResponsesData.rejected, fetchDailyAIResponsesDataRejected)
      .addCase(fetchWeeklyAIResponsesData.pending, fetchWeeklyAIResponsesDataLoading)
      .addCase(fetchWeeklyAIResponsesData.fulfilled, fetchWeeklyAIResponsesDataFulfilled)
      .addCase(fetchWeeklyAIResponsesData.rejected, fetchWeeklyAIResponsesDataRejected)
      .addCase(fetchMonthlyAIResponsesData.pending, fetchMonthlyAIResponsesDataLoading)
      .addCase(fetchMonthlyAIResponsesData.fulfilled, fetchMonthlyAIResponsesDataFulfilled)
      .addCase(fetchMonthlyAIResponsesData.rejected, fetchMonthlyAIResponsesDataRejected)
      .addCase(fetchKPIsData.fulfilled, fetchKPIsDataFulfilled)
      .addCase(fetchKPIsData.rejected, fetchKPIsDataRejected)
  },
})

export default homeSlice.reducer