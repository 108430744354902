import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import Text from '../../components/Text'
import { fetchCourses } from '../../store/tagging/tagging.slice'
import styles from './Popup.module.css'
import { saveFunnelOptions } from '../../store/funnelOptions/funnelOptions.slice'


const Option = ({
    index,
    option,
    handleAdd,
    handleSetIconPath,
    handleSetId,
    handleSetTitle,
    handleUpOption,
    handleTopOption,
    handleDownOption,
    handleBottomOption,
    handleDeleteOption,
    disableUp,
    disableDown,
}) => {
    const [iconSrc, setIconSrc] = useState(option.iconPath)
    return (
        <Layout fullWidth key={option.id} gap={8} color={'#eee'} padding={[8]}>
            <Layout row justifyContent='flex-start' fullWidth>
                <Button text={'Top'} type='secondary' onClick={() => handleTopOption(index)} disabled={disableUp} />
                <Button text={'Up'} type='secondary' onClick={() => handleUpOption(index)} disabled={disableUp} />
                <Button text={'Bottom'} type='secondary' onClick={() => handleBottomOption(index)} disabled={disableDown} />
                <Button text={'Down'} type='secondary' onClick={() => handleDownOption(index)} disabled={disableDown} />
                <Button text={'Delete'} type='secondary' onClick={() => handleDeleteOption(index)} />
                <Button text={'Add'} type='secondary' onClick={() => handleAdd(index)} />
            </Layout>
            <Layout gap={4} alignItems='flex-start' fullWidth>
                <Text text='ID:' />
                <input
                    defaultValue={option.id}
                    className={styles.input}
                    onChange={e => handleSetId(index, e.target.value)} />
            </Layout>
            <Layout gap={4} alignItems='flex-start' fullWidth>
                <Text text='Title:' />
                <input
                    defaultValue={option?.title}
                    className={styles.input}
                    onChange={e => handleSetTitle(index, e.target.value)} />
            </Layout>
            <Layout row gap={16} fullWidth alignItems='flex-start'>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Icon Path:' />
                    <input
                        defaultValue={option?.iconPath}
                        className={styles.input}
                        onChange={e => { setIconSrc(e.target.value); handleSetIconPath(index, e.target.value) }} />
                </Layout>
                <img src={iconSrc} alt='' width={80} height={80} style={{ borderRadius: '40px' }} />
            </Layout>
        </Layout>
    )
}

const Popup = ({ id }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const savedData = useSelector(state => id ? state.funnelOptions.data?.funnelOptions?.find(d => d.id === id) : undefined)
    const [data, setData] = useState({})
    const [showedData, setShowedData] = useState({})

    useEffect(() => {
        setData(savedData)
        setShowedData(savedData)
    }, [savedData])

    useEffect(() => {
        dispatch(fetchCourses())
    }, [dispatch])

    const handleSave = () => {
        dispatch(saveFunnelOptions({ id, data }))
    }

    const handleAdd = (i = undefined) => {
        if (i !== undefined) {
            const tmp = [...data.options]
            tmp.splice(i + 1, 0, {
                id: '',
                title: '',
                iconPath: ''
            })
            setData(d => {
                return {
                    ...d,
                    options: tmp
                }
            })
            setShowedData(d => {
                return {
                    ...d,
                    options: tmp
                }
            })
        } else {

            setData(d => {
                return {
                    ...d,
                    options: [
                        ...d.options,
                        {
                            id: '',
                            title: '',
                            iconPath: ''
                        }
                    ]
                }
            })
            setShowedData(d => {
                return {
                    ...d,
                    options: [
                        ...d.options,
                        {
                            id: '',
                            title: '',
                            iconPath: ''
                        }
                    ]
                }
            })
        }

    }

    const handleSetId = (index, id) => {
        setData(d => {
            return {
                ...d,
                options: [
                    ...d.options.map((o, i) => i === index ? { ...o, id } : o),
                ]
            }
        })
    }

    const handleSetTitle = (index, title) => {
        setData(d => {
            return {
                ...d,
                options: [
                    ...d.options.map((o, i) => i === index ? { ...o, title } : o),
                ]
            }
        })
    }

    const handleSetIconPath = (index, iconPath) => {
        setData(d => {
            return {
                ...d,
                options: [
                    ...d.options.map((o, i) => i === index ? { ...o, iconPath } : o),
                ]
            }
        })
    }

    const handleUpOption = (i) => {
        if (i === 0) return;
        setData(d => {
            const options = [...d.options]
            const tmp = options[i];
            options[i] = options[i - 1]
            options[i - 1] = tmp

            setShowedData({
                ...d,
                options: options,
            })

            return {
                ...d,
                options: options,
            }
        })
    }

    const handleTopOption = (i) => {
        if (i === 0) return;
        setData(d => {
            let options = [...d.options]
            const tmp = options[i];
            options = options.filter((v, ii) => ii !== i)
            options.unshift(tmp)

            setShowedData({
                ...d,
                options: options,
            })

            return {
                ...d,
                options: options,
            }
        })
    }

    const handleDownOption = (i) => {
        if (i >= data.options.length - 1) return;
        setData(d => {
            const options = [...d.options]
            const tmp = options[i];
            options[i] = options[i + 1]
            options[i + 1] = tmp

            setShowedData({
                ...d,
                options: options,
            })

            return {
                ...d,
                options: options,
            }
        })
    }

    const handleBottomOption = (i) => {
        if (i >= data.options.length - 1) return;
        setData(d => {
            let options = [...d.options]
            const tmp = options[i];
            options = options.filter((v, ii) => ii !== i)
            options.push(tmp)

            setShowedData({
                ...d,
                options: options,
            })

            return {
                ...d,
                options: options,
            }
        })
    }

    const handleDeleteOption = (i) => {
        setData(d => {
            const options = [...d.options]
            options.splice(i, 1);

            setShowedData({
                ...d,
                options: options,
            })

            return {
                ...d,
                options: options,
            }
        })
    }

    return (
        <Layout classes={[styles.backdrop, id === undefined ? null : styles.display]} alignItems='flex-end' onClick={() => { navigate(`/funnel_options`) }}>
            <Layout classes={[styles.container]} onClick={e => e.stopPropagation()} padding={[32, 32, 0, 32]} justifyContent='flex-start' alignItems='flex-start' gap={32}>
                <Layout gap={4} alignItems='flex-start' fullWidth>
                    <Text text='Funnel Poll ID:' />
                    <input
                        value={data?.id}
                        className={styles.input}
                        disabled />
                </Layout>
                <Layout alignItems='flex-start' fullWidth gap={16}>
                    <Layout row fullWidth justifyContent='space-between'>
                        <Text text='Options:' />
                        <Button text={'Add'} type='secondary' onClick={() => handleAdd(undefined)} />
                    </Layout>
                    <Layout gap={16} fullWidth alignItems='flex-start'>
                        {
                            showedData?.options?.map((option, index) =>
                                <Option
                                    key={`${option.id}-${index}`}
                                    option={option}
                                    index={index}
                                    handleSetId={handleSetId}
                                    handleSetTitle={handleSetTitle}
                                    handleSetIconPath={handleSetIconPath}
                                    handleTopOption={handleTopOption}
                                    handleUpOption={handleUpOption}
                                    handleDownOption={handleDownOption}
                                    handleBottomOption={handleBottomOption}
                                    handleDeleteOption={handleDeleteOption}
                                    handleAdd={handleAdd}
                                    disableUp={index <= 0}
                                    disableDown={index >= showedData.options.length - 1} />)

                        }
                    </Layout>
                </Layout>
                <Layout row classes={[styles.bottomContainer]} fullWidth justifyContent='flex-start' padding={[8, 0]}>
                    <Button
                        text={'Save'}
                        onClick={handleSave}
                        disabled={false} />
                </Layout>
            </Layout>
        </Layout>
    )
}

export default Popup