import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DatabaseService from '../../services/DatabaseService'
import FunctionsService from '../../services/FunctionsService'
import Status from '../status'
import {
    fetchFunnelsOptionsFulfilled,
    fetchFunnelsOptionsLoading,
    fetchFunnelsOptionsRejected,

    fetchMarketingFunnelsFulfilled,
    fetchMarketingFunnelsLoading,
    fetchMarketingFunnelsRejected,

    saveFunnelOptionsFulfilled,
    saveFunnelOptionsLoading,
    saveFunnelOptionsRejected,

    saveMarketingFunnelFulfilled,
    saveMarketingFunnelLoading,
    saveMarketingFunnelRejected,
} from './funnelOptions.actions'

export const fetchFunnelsOptions = createAsyncThunk('funnelOptions/fetchFunnelsOptions', async (_) => {
    return DatabaseService.getFunnelOptions()
})

export const saveFunnelOptions = createAsyncThunk('funnelOptions/saveFunnelOptions', async ({ id, data }) => {
    return FunctionsService.updateFunnelOptions(id, data);
})

export const fetchMarketingFunnels = createAsyncThunk('funnelOptions/fetchMarketingFunnels', async (_) => {
    return DatabaseService.getMarketingFunnels()
})

export const saveMarketingFunnel = createAsyncThunk('funnelOptions/saveMarketingFunnels', async ({ id, data, navigate }) => {
    return FunctionsService.updateMarketingFunnel(id, data);
})

export const funnelOptionsSlice = createSlice({
    name: 'funnelOptions',
    initialState: {
        data: {
            funnelOptions: [],
            marketingFunnels: {},
        },
        fetchStatus: Status.None,
        fetchMarketingFunnelsStatus: Status.None,
        saveStatus: Status.None,
        saveMarketingFunnelStatus: Status.None,
    },
    extraReducers(builder) {
        builder
            .addCase(fetchFunnelsOptions.pending, fetchFunnelsOptionsLoading)
            .addCase(fetchFunnelsOptions.fulfilled, fetchFunnelsOptionsFulfilled)
            .addCase(fetchFunnelsOptions.rejected, fetchFunnelsOptionsRejected)

            .addCase(fetchMarketingFunnels.pending, fetchMarketingFunnelsLoading)
            .addCase(fetchMarketingFunnels.fulfilled, fetchMarketingFunnelsFulfilled)
            .addCase(fetchMarketingFunnels.rejected, fetchMarketingFunnelsRejected)

            .addCase(saveFunnelOptions.pending, saveFunnelOptionsLoading)
            .addCase(saveFunnelOptions.fulfilled, saveFunnelOptionsFulfilled)
            .addCase(saveFunnelOptions.rejected, saveFunnelOptionsRejected)

            .addCase(saveMarketingFunnel.pending, saveMarketingFunnelLoading)
            .addCase(saveMarketingFunnel.fulfilled, saveMarketingFunnelFulfilled)
            .addCase(saveMarketingFunnel.rejected, saveMarketingFunnelRejected)
    },
})

export default funnelOptionsSlice.reducer