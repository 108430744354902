import Layout from "../../components/Layout"
import DateButton from "../../components/DateButton"
import styles from './MarketingFunnels.module.css'
import React, { useState } from 'react'
import FunctionsService from "../../services/FunctionsService"
import { addDays } from 'date-fns';
import Button from '../../components/Button'
import Text from '../../components/Text'
import Divider from '../../components/Divider/Divider'
import Copy from "../../assets/icons/Copy"
import ShowSkippedDialog from "../../components/Dialogs/ShowSkippedDialog"

const MarketingFunnels = () => {
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(addDays(new Date(), -6))
    const [endDate, setEndDate] = useState(new Date())
    const [data, setData] = useState([])
    const [other, setOther] = useState(null)
    const [showSkipped, setShowSkipped] = useState(null)

    const handleClick = async () => {
        const startTime = Math.floor(startDate.getTime() / 1000);
        const endTime = Math.floor(endDate.getTime() / 1000);

        setLoading(true)
        FunctionsService.getRevenueBreakdown(startTime, endTime)
            .then((d) => {
                console.log(d);
                
                const res = Object.keys(d.data).map(funnelId => {
                    const dd = d.data[funnelId]
                    dd.id = funnelId
                    return dd
                })
                res.sort((a, b) => b.total - a.total)
                setData(res)
                setOther(d.other)
                console.log(res);
                setLoading(false)
            })
    }

    const handleCopy = (data) => {
        const toCopy = `Between ${startDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: '2-digit' })} and ${endDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: '2-digit' })}:
There were a total of ${data.count} transactions, summing up to $${data.total / 100}.
${data.create.count} of them were new subscribers ($${data.create.total / 100})
${data.other.count} of them were one-time payments ($${data.other.total / 100})
${data.update.count} of them were recurring payments ($${data.update.total / 100})`
        navigator.clipboard.writeText(toCopy);
    }

    return (
        <Layout classes={[styles.container]} alignItems='normal' justifyContent='normal' padding={[32]} gap={16}>
            <ShowSkippedDialog show={showSkipped} data={other?.skipped} onCancel={() => setShowSkipped(false)} />
            <Layout row justifyContent="space-between">
                <Layout row gap={32} >
                </Layout>
                <Layout padding={[0, 32]}>
                    <DateButton isActive={!loading} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                </Layout>
            </Layout>
            <Layout row justifyContent="flex-start" spacing={32}>
                <Button text={'Generate'} onClick={handleClick} disabled={loading} />
                {
                    other ?
                        <Layout row spacing={16}>
                            <Text text={`Charges: ${other.charges}`} bold />
                            <Text text={`Tagged: ${other.labeledCharges}`} bold />
                            <Text text={`Undefined: ${other.undefinedCharges}`} bold />
                            <Button text={'Show skipped'} onClick={() => setShowSkipped(true)} />
                        </Layout> :
                        null
                }
            </Layout>
            <Layout scroll fullWidth alignItems='normal' justifyContent='normal' gap={8} padding={[8]}>
                <Layout row justifyContent="space-between" padding={[2]}>
                    <Text text={"Funnel Id"} className={styles.cellId} bold />
                    <Layout row gap={16}>
                        <Text text={"Total"} className={styles.cellData} bold />
                        <Text text={"Create/Update/Other"} className={styles.cellDataBig} bold />
                        <Text text={"From"} className={styles.cellData} bold />
                        <Text text={"Poll"} className={styles.cellData} bold />
                        <Text text={"Promo"} className={styles.cellData} bold />
                    </Layout>
                </Layout>
                <Layout fullWidth alignItems='normal' justifyContent='normal'>
                    <Divider color={'rgba(0, 0, 0, 0.1)'} />
                    {
                        data.map(d => {
                            return (
                                <React.Fragment key={d.id}>
                                    <Layout row justifyContent="space-between" padding={[2]}>
                                        <Text text={<><Copy size={14} style={{ cursor: 'pointer', marginRight: '4px' }} onClick={() => handleCopy(d)} />{d.id}</>} className={styles.cellId} />
                                        <Layout row gap={16}>
                                            <Text text={`$${d.total / 100} (${d.count})`} className={styles.cellData} />
                                            <Text text={`$${d.create.total / 100} (${d.create.count})/$${d.update.total / 100} (${d.update.count})/$${d.other.total / 100} (${d.other.count})`} className={styles.cellDataBig} />
                                            <Text text={`$${d.from.total / 100} (${d.from.count})`} className={styles.cellData} />
                                            <Text text={`$${d.info.total / 100} (${d.info.count})`} className={styles.cellData} />
                                            <Text text={`$${d.promo.total / 100} (${d.promo.count})`} className={styles.cellData} />
                                        </Layout>
                                    </Layout>
                                    <Divider color={'rgba(0, 0, 0, 0.1)'} />
                                </React.Fragment>
                            )
                        })
                    }
                </Layout>

            </Layout>
        </Layout>
    )
}

export default MarketingFunnels