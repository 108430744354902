import React from 'react'
import Backdrop from './Backdrop/Backdrop'
import Layout from '../Layout';
import Text from '../Text';

const ShowSkippedDialog = ({ show, data, onCancel }) => {

    if (!show) return null;
    return (
        <Backdrop onCancel={onCancel}>
            <Layout color={'white'} border={8} padding={[32]} gap={32} alignItems='flex-start' justifyContent='flex-start' style={{width: '600px', maxHeight: '80vh', overflow: 'auto'}}>
                <Layout alignItems='flex-start' gap={16}>
                    <Text text='From:' bold />
                    <Layout alignItems='flex-start' gap={8}>
                        {
                            Object.keys(data.from).map((k, i) => <Text key={k} text={`${k} (${data.from[k]})`} />)
                        }
                    </Layout>
                </Layout>
                <Layout alignItems='flex-start' gap={16}>
                    <Text text='Info:' bold />
                    <Layout alignItems='flex-start' gap={8}>
                        {
                            Object.keys(data.info).map((k, i) => <Text key={k} text={`${k} (${data.info[k]})`} />)
                        }
                    </Layout>
                </Layout>
                <Layout alignItems='flex-start' gap={16}>
                    <Text text='Promo:' bold />
                    <Layout alignItems='flex-start' gap={8}>
                        {
                            Object.keys(data.promo).map((k, i) => <Text key={k} text={`${k} (${data.promo[k]})`} />)
                        }
                    </Layout>
                </Layout>
            </Layout>
        </Backdrop>
    )
}

export default ShowSkippedDialog